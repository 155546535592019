import React, { useEffect } from "react"
import ReactLoading from "react-loading"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"
import { Grid } from "@material-ui/core"

import Seo from "../components/SEO"

const IndexPage = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/home")
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>
      <Seo title="TOP/トップ" />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "black",
          textAlign: "center",
        }}
      >
        <Grid item>
          <StaticImage
            src="../images/icon.png"
            alt="icon"
            placeholder="blurred"
          />
          <ReactLoading
            type="cylon"
            color="white"
            height={"100%"}
            width={"100%"}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default IndexPage
